<template>
  <base-section id="k-d-a-careers-life-keda" space="0" :top-inner-shadow="!g_bLowerBr">
    <v-container class="pa-0 px-sm-4 pa-md-0 main-bw-400--text">
      <v-row
        v-if="!g_bLowerBr"
        no-gutters
        align="center"
        justify="space-between"
        class="py-sm-8 py-md-15"
      >
        <v-col cols="auto">
          <h1 class="kda-ts-46pt wt-extrabold">{{ m_strTitle }}</h1>
        </v-col>

        <v-col cols="auto">
          <div class="d-flex align-center" style="gap: 14px">
            <p class="kda-ts-28pt wt-extrabold">{{ iLifeIndex + 1 }}/{{ m_arrLives.length }}</p>
            <v-btn icon elevation="1" class="main-bw-300" @click="next">
              <v-icon color="white">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-window v-model="iLifeIndex">
        <v-window-item
          v-for="(life, index) in m_arrLives"
          :key="`life-window-slider-container-${index + 1}`"
          eager
        >
          <v-row
            no-gutters
            align="center"
            justify="center"
            class="py-4 py-sm-8 py-md-15 mx-0 mx-sm-6"
            :style="{ gap: g_bLowerBr ? '32px' : '86px' }"
          >
            <v-col class="px-7 pa-sm-0" :style="{ minHeight: g_bLowestBr ? '168px' : 'auto' }">
              <h4 :class="`kda-ts-${g_bLowerBr ? '24' : '46'}pt ln-150 wt-extrabold`">
                {{ life.strTitle }}
              </h4>

              <p :class="`kda-ts-${g_bLowerBr ? '12' : '24'}pt nunito ln-170 mt-3 mt-sm-4`">
                {{ life.strDesc }}
              </p>
            </v-col>

            <v-col cols="12" md="auto" class="order-first order-md-last">
              <base-img
                :src="life.imgSrc"
                :contain="!g_bLowestBr"
                :cover="g_bLowestBr"
                :height="g_bLowestBr ? 224 : 300"
              ></base-img>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>

      <v-row v-if="g_bLowerBr" class="pb-5 px-7 pt-2 ma-0" justify="space-between" align="center">
        <v-col cols="auto">
          <v-btn icon elevation="1" class="main-bw-300" @click="prev">
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-item-group v-model="iLifeIndex" class="text-center my-2" mandatory>
            <v-item
              v-for="n in m_arrLives.length"
              :key="`life-keda-window-btn-slider-${n}`"
              v-slot="{ active, toggle }"
            >
              <v-btn :input-value="active" icon x-small @click="toggle">
                <v-icon small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-col>

        <v-col cols="auto">
          <v-btn icon elevation="1" class="main-bw-300" @click="next">
            <v-icon color="white">mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!g_bLowerBr" class="pa-0" style="background: #fbfbfb" fluid>
      <p class="kda-ts-46pt ln-150 wt-extrabold text-center" style="padding: 100px 0">
        {{ m_strBottom }}
      </p>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDACareersKeda',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  data() {
    return { iLifeIndex: 0 };
  },

  computed: {
    m_strTitle() {
      return '#LifeAtKeDA';
    },
    m_strBottom() {
      return this.$vuetify.lang.t(`$vuetify.kda.careers.lives.bottom`);
    },
    m_arrLives() {
      const result = [];

      for (let i = 1; i <= 5; i++) {
        result.push({
          strTitle: this.$vuetify.lang.t(`$vuetify.kda.careers.lives.life${i}.title`),
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.careers.lives.life${i}.desc`),
          imgSrc: require(`@/assets/photos/careers-life-${i}.png`)
        });
      }

      return result;
    }
  },

  methods: {
    next() {
      this.iLifeIndex = this.iLifeIndex + 1 === this.m_arrLives.length ? 0 : this.iLifeIndex + 1;
    },
    prev() {
      this.iLifeIndex = this.iLifeIndex - 1 < 0 ? this.m_arrLives.length - 1 : this.iLifeIndex - 1;
    }
  }
};
</script>
